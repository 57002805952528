import * as React from "react";

function GearCenter(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={292.589}
      height={292.589}
      viewBox="0 0 292.589 292.589"
      {...props}
    >
      <defs>
        <filter
          id="gearC"
          x={0}
          y={0}
          width={292.589}
          height={292.589}
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy={3} />
          <feGaussianBlur stdDeviation={3} result="blur" />
          <feFlood floodColor="#c67100" floodOpacity={0.251} />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g filter="url(#gearC)">
        <path
          data-name="Path 2040"
          d="M58.15 183.917a98.613 98.613 0 0011.982 19.306l-8.408 14.924a11.02 11.02 0 003.099 14.29l20.974 15.242a10.968 10.968 0 0014.549-1.469l11.52-12.514a96.882 96.882 0 0022.936 5.693l4.66 16.637a10.989 10.989 0 0012.288 7.907l25.614-4.057a10.99 10.99 0 009.243-11.317l-.702-16.648a98 98 0 0021.565-12.961l14.339 8.066a11.015 11.015 0 0014.29-3.099l15.238-20.977a10.972 10.972 0 00-1.47-14.549l-11.901-11.004a95.758 95.758 0 005.893-24.159l14.995-4.178a10.99 10.99 0 007.901-12.293l-4.057-25.618a10.99 10.99 0 00-11.316-9.249l-15.337.63a96.376 96.376 0 00-12.643-20.959l7.413-13.188a11.02 11.02 0 00-3.098-14.291L206.79 38.879a10.968 10.968 0 00-14.55 1.47L182.3 51.16a96.667 96.667 0 00-25.117-6.14l-4.048-14.444a10.989 10.989 0 00-12.288-7.907l-25.613 4.056a10.989 10.989 0 00-9.243 11.317l.614 14.989A96.263 96.263 0 0084.067 67.07l-13.284-7.486a11.016 11.016 0 00-14.291 3.099L41.26 83.659a10.972 10.972 0 001.468 14.55l11.846 10.924a94.523 94.523 0 00-5.342 24.031l-15.504 4.299a10.99 10.99 0 00-7.905 12.293l4.057 25.618a10.99 10.99 0 0011.316 9.25z"
          fill="#ffa000"
        />
      </g>
    </svg>
  );
}

export default GearCenter;
