import * as React from "react";

function Logo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 83 48" {...props}>
      <g data-name="Group 308">
        <g data-name="Group 307" fill="#fff">
          <path
            data-name="Path 424"
            d="M48.706 15.287a3801.365 3801.365 0 01-3.843 17.219h-5.074c-.109 0-.254-.217-.29-.363-.58-2.1-1.2-4.169-1.776-6.271-.363-1.341-.761-2.683-1.16-4.169-1.015 3.7-2.03 7.25-3.009 10.8h-5.257c-.254-1.051-.471-2.139-.725-3.19-1.015-4.531-2.03-9.1-3.045-13.63a.277.277 0 00-.073-.181c-.036-.254.145-.181.254-.181h4.855v.29c.435 2.248.87 4.531 1.341 6.779l.435 2.175a.733.733 0 00.218-.4c.435-1.523.87-3.081 1.269-4.6.4-1.414.761-2.864 1.16-4.278h5.148a.617.617 0 00.036.254l2.5 8.918a2.22 2.22 0 00.145.217q.489-2.61.979-5.111c.29-1.45.58-2.864.87-4.278z"
          />
          <path
            data-name="Path 425"
            d="M59.763 15.287l1.2.217a6.478 6.478 0 015.148 4.966 13.264 13.264 0 01.145 6.525 6.63 6.63 0 01-5.836 5.329 16.158 16.158 0 01-2.284.181c-2.356.036-4.713 0-7.033 0h-.548V15.287zm-3.808 13.232c.87 0 1.74.036 2.574 0a2.381 2.381 0 002.356-2.03 10.451 10.451 0 00-.036-5.075 2.468 2.468 0 00-1.994-1.994c-.943-.181-1.921-.181-2.9-.29z"
          />
          <path
            data-name="Path 426"
            d="M74.661 15.287v12.942h8.338v4.241H69.261V15.287z"
          />
        </g>
      </g>
      <path
        data-name="Path 430"
        d="M30.542 14.471h2.936s-1.7-8.406-3.806-10.8h.544s7.576.823 13.268 10.981h3.226A24.107 24.107 0 0029.672.664L27.134.127a39.64 39.64 0 00-6.018 0c-1.74.25-4.966.644-7.069 1.753-.544.286-8.555 3.112-13.087 14.844 0 0-4.386 13.52 5.583 23.822 0 0 12.869 13.342 29.726 4.328a23.467 23.467 0 009.969-10.659h-3.335l-1.631 2.79a24.324 24.324 0 01-11.383 7.476A31.409 31.409 0 0033.26 34.43l-2.936-.036s-1.849 9.3-5.4 10.73c0 0-4.133-.215-5.873-12.09l7.285-.178-.653-2.826h-7.14l-.363-2.468a77.092 77.092 0 01.218-9.443l4.966-.179-.435-2.861-4.024.036s1.378-10.409 5.946-12.376c0 .071 3.225.179 5.691 11.732z"
        fill="#fff"
      />
      <g fill="#00283b">
        <path
          data-name="Path 431"
          d="M5.492 14.956h10.839a48.538 48.538 0 013.988-11.709A20.075 20.075 0 005.492 14.956z"
        />
        <path
          data-name="Path 432"
          d="M4.296 17.787h11.636a79.081 79.081 0 000 12.144H4.296a21 21 0 010-12.144z"
        />
        <path
          data-name="Path 433"
          d="M5.492 32.76h10.911s.109 4.35 3.915 11.782c-.036 0-10.295-2.067-14.826-11.782z"
        />
      </g>
    </svg>
  );
}

export default Logo;
