import * as React from "react";

function Fax(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={84}
      height={84}
      viewBox="0 0 84 84"
      {...props}
    >
      <g data-name="Group 283">
        <g data-name="Group 196">
          <g data-name="Group 193">
            <rect
              data-name="Rectangle 467"
              width={84}
              height={84}
              rx={42}
              fill="#00283b"
            />
          </g>
        </g>
        <g fill="#ffa000">
          <path
            data-name="Path 209"
            d="M25.519 26.633H25.5a5.425 5.425 0 00-5.5 5.333v28.96A3.127 3.127 0 0023.171 64h4.678a3.127 3.127 0 003.171-3.074v-28.96a5.425 5.425 0 00-5.501-5.333z"
          />
          <path
            data-name="Path 210"
            d="M62.639 30.894h-1.361v11.031H34.025V30.894H31.3v31.743a1.36 1.36 0 001.36 1.36h29.975a1.36 1.36 0 001.36-1.36V32.256a1.36 1.36 0 00-1.356-1.362zM39.082 57.88a1.36 1.36 0 11.4-.962 1.37 1.37 0 01-.4.964zm0-5.443a1.359 1.359 0 11.4-.961 1.366 1.366 0 01-.4.964zm0-5.442a1.359 1.359 0 11.4-.961 1.37 1.37 0 01-.4.96zm5.442 10.884a1.36 1.36 0 11.4-.962 1.37 1.37 0 01-.4.965zm0-5.443a1.359 1.359 0 11.4-.961 1.367 1.367 0 01-.4.965zm0-5.442a1.359 1.359 0 11.4-.961 1.37 1.37 0 01-.4.961zm5.442 10.884a1.36 1.36 0 11.4-.962 1.37 1.37 0 01-.4.966zm0-5.443a1.359 1.359 0 11.4-.961 1.367 1.367 0 01-.4.966zm0-5.442a1.359 1.359 0 11.4-.961 1.37 1.37 0 01-.4.962zm7.231 5.841h-2.751a1.36 1.36 0 010-2.721H57.2a1.36 1.36 0 010 2.721zm0-5.442h-2.751a1.36 1.36 0 010-2.721H57.2a1.36 1.36 0 010 2.721z"
          />
          <path
            data-name="Path 211"
            d="M58.557 21.36a1.36 1.36 0 00-1.36-1.36H38.106a1.36 1.36 0 00-1.36 1.36v20.283h21.812V21.36zm-6.8 17.686h-8.558a1.36 1.36 0 110-2.721h8.558a1.36 1.36 0 010 2.721zm0-5.442h-8.558a1.36 1.36 0 110-2.721h8.558a1.36 1.36 0 010 2.721zm0-5.442h-8.558a1.36 1.36 0 110-2.721h8.558a1.36 1.36 0 010 2.721z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Fax;
