import * as React from "react";

function Phone(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={84}
      height={84}
      viewBox="0 0 84 84"
      {...props}
    >
      <g data-name="Group 197">
        <g data-name="Group 192">
          <rect
            data-name="Rectangle 466"
            width={84}
            height={84}
            rx={42}
            fill="#00283b"
          />
        </g>
        <g data-name="Group 202" fill="#ffa000">
          <path
            data-name="Path 205"
            d="M42.242 30.902c7.413.076 12.058-.405 12.685 8.166h9.04c0-13.278-11.1-15.067-21.96-15.067S20.046 25.79 20.046 39.067h8.97c.694-8.734 5.878-8.24 13.226-8.165z"
          />
          <path
            data-name="Path 206"
            d="M24.723 42.504c2.3 0 4.208.137 4.633-2.122a5.765 5.765 0 00.09-1.058H20c0 3.358 2.115 3.18 4.723 3.18z"
          />
          <path
            data-name="Path 207"
            d="M54.598 39.328h-.088a5.046 5.046 0 00.1 1.058c.448 2.083 2.357 1.954 4.645 1.954 2.621 0 4.745.172 4.745-3.012h-9.4z"
          />
          <path
            data-name="Path 208"
            d="M50.475 36.085V34.75c0-.6-.654-.635-1.462-.635h-1.32c-.807 0-1.461.037-1.461.635v2.043h-9.331V34.75c0-.6-.654-.635-1.461-.635h-1.32c-.807 0-1.461.037-1.461.635v2.5c-2.127 2.324-9.085 12.219-9.338 13.417v7.993a1.309 1.309 0 001.273 1.339h33.943a1.309 1.309 0 001.273-1.339v-8.032c-.25-1.164-7.209-11.054-9.335-13.378zM36.437 51.744a1.3 1.3 0 111.237-1.3 1.27 1.27 0 01-1.237 1.3zm0-4.463a1.3 1.3 0 111.237-1.3 1.27 1.27 0 01-1.237 1.299zm0-4.462a1.3 1.3 0 111.237-1.3 1.27 1.27 0 01-1.237 1.299zm5.092 8.925a1.3 1.3 0 111.237-1.3 1.27 1.27 0 01-1.237 1.3zm0-4.463a1.3 1.3 0 111.237-1.3 1.27 1.27 0 01-1.237 1.299zm0-4.462a1.3 1.3 0 111.237-1.3 1.27 1.27 0 01-1.237 1.299zm5.092 8.925a1.3 1.3 0 111.237-1.3 1.27 1.27 0 01-1.237 1.3zm0-4.463a1.3 1.3 0 111.237-1.3 1.27 1.27 0 01-1.237 1.299zm0-4.462a1.3 1.3 0 111.237-1.3 1.27 1.27 0 01-1.237 1.299z"
          />
        </g>
      </g>
    </svg>
  );
}

export default Phone;
